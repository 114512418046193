import { Empty } from 'common-front/src/components/empty/empty';
import { TabViewResponsive } from 'common-front/src/components/tabViewsReponsive/tabViewResponsive';
import { DelegationAccreditationsLoader } from 'common-front/src/delegations/accreditations/show/delegationAccreditationsLoader';
import { DelegationDocuments } from 'common-front/src/delegations/accreditations/show/delegationDocuments';
import { DelegationMembers } from 'common-front/src/delegations/members/show/delegationMembers';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { DelegationsPaths } from 'common/src/delegationsPaths';
import { Flex } from 'common/src/designSystem/components/flex';
import { DelegationState } from 'common/src/generated/types';
import {
    DelegationParams,
    DelegationsPaths as DelegationsPathsV2
} from 'common/src/util/paths/delegationsPaths';
import { compact } from 'lodash-es';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useDelegationsSpaceDelegationQuery } from '../../generated/graphqlHooks';
import { SpaceContainer } from '../common/spaceContainer';
import { DelegationDashboard } from './dashboard/delegationDashboard';
import { DelegationMenu } from './delegationMenu';
import { DelegationsSpaceHeader } from './delegationsSpaceHeader';
import {
    DelegationCreateMember,
    DelegationUpdateMember
} from './members/update/delegationCreateUpdateMember';
import { DelegationProfile } from './profile/delegationProfile';

export const Delegation = () => {
    const {
        translate,
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const { data, loader, reload } = useDelegationsSpaceDelegationQuery({
        delegationId,
        organizationId
    });
    const extraItems = React.useMemo(() => {
        const delegations = data.organization?.delegations.nodes ?? [];

        return <DelegationMenu delegations={delegations} />;
    }, [data.organization]);
    const getDelegationParams = (isRouteComponent: boolean): DelegationParams => ({
        organizationId: isRouteComponent ? ':organizationId' : organizationId,
        eventId: isRouteComponent ? ':eventId' : eventId,
        delegationId: isRouteComponent ? ':delegationId' : delegationId
    });

    React.useEffect(() => {
        const eventName =
            data.organization?.delegation?.event?.name ?? data.organization?.name ?? 'Recrewteer';

        document.title = compact([eventName, data.organization?.delegation?.name]).join(' - ');
    }, [data.organization]);

    return (
        loader || (
            <SpaceContainer
                extraItems={extraItems}
                isLoading={false}
                organization={data.organization}
                spaceDesign={{
                    themeColors: data.organization?.delegationsSpaceDesign?.colors,
                    bannerSrc: data.organization?.delegationsSpaceDesign?.banner?.url,
                    logoSrc: data.organization?.delegationsSpaceDesign?.logo?.url
                }}
                userInfo={data.user.userInfo}
            >
                <Switch>
                    <Route
                        exact
                        path={[
                            DelegationsPaths.DELEGATION({
                                organizationId: ':organizationId',
                                delegationId: ':delegationId'
                            }),
                            DelegationsPaths.DELEGATION({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId'
                            })
                        ]}
                        children={
                            <Empty
                                path={DelegationsPathsV2.DELEGATION_MEMBERS({
                                    organizationId,
                                    eventId,
                                    delegationId
                                })}
                                replace={true}
                            />
                        }
                    />

                    <Route
                        path={[
                            DelegationsPaths.MEMBER_EDIT({
                                organizationId: ':organizationId',
                                delegationId: ':delegationId',
                                userInfoId: ':userInfoId',
                                formId: ':formId'
                            }),
                            DelegationsPaths.MEMBER_EDIT({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId',
                                userInfoId: ':userInfoId',
                                formId: ':formId'
                            })
                        ]}
                        children={<DelegationUpdateMember reload={reload} />}
                    />

                    <Route
                        path={[
                            DelegationsPaths.MEMBER_CREATE({
                                organizationId: ':organizationId',
                                delegationId: ':delegationId',
                                formId: ':formId'
                            }),
                            DelegationsPaths.MEMBER_CREATE({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId',
                                formId: ':formId'
                            })
                        ]}
                        children={<DelegationCreateMember reload={reload} />}
                    />

                    <Route
                        path={[
                            DelegationsPaths.DELEGATION_PROFILE({
                                organizationId: ':organizationId',
                                delegationId: ':delegationId'
                            }),
                            DelegationsPaths.DELEGATION_PROFILE({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId'
                            })
                        ]}
                        children={<DelegationProfile />}
                    />

                    <Route
                        children={
                            <>
                                <DelegationsSpaceHeader
                                    delegation={data.organization.delegation}
                                    name={data.user.userInfo.firstName}
                                />

                                <Flex direction="column">
                                    <TabViewResponsive.Root>
                                        <TabViewResponsive.Section
                                            title={translate('tableau_de_bord_24627')}
                                            getPath={(isRouteComponent: boolean = false) =>
                                                DelegationsPathsV2.DELEGATION_DASHBOARD(
                                                    getDelegationParams(isRouteComponent)
                                                )
                                            }
                                        >
                                            <DelegationDashboard />
                                        </TabViewResponsive.Section>

                                        <TabViewResponsive.Section
                                            title={translate(
                                                'membres_125561',
                                                data.organization.delegation.numberOfResources
                                            )}
                                            getPath={(isRouteComponent: boolean = false) =>
                                                DelegationsPathsV2.DELEGATION_MEMBERS(
                                                    getDelegationParams(isRouteComponent)
                                                )
                                            }
                                        >
                                            <DelegationMembers
                                                basePath={DelegationsPathsV2.DELEGATION_MEMBERS({
                                                    organizationId,
                                                    eventId,
                                                    delegationId
                                                })}
                                                canEditMembers={
                                                    data.organization.delegation.state !==
                                                    DelegationState.Locked
                                                }
                                                customFields={data.organization.customFields.nodes}
                                                delegation={data.organization.delegation}
                                                deleteFromEvent={true}
                                                showAccreditActions={false}
                                                showDeleteFromEvent={false}
                                                showDocumentsDownload={
                                                    data.organization.allowDelegationSpaceDownload
                                                }
                                                getEditPath={(userInfoId, formId) =>
                                                    DelegationsPaths.MEMBER_EDIT({
                                                        delegationId,
                                                        eventId,
                                                        organizationId,
                                                        userInfoId,
                                                        formId
                                                    })
                                                }
                                            />
                                        </TabViewResponsive.Section>

                                        {eventId && (
                                            <TabViewResponsive.Section
                                                title={translate('accr_ditations_39450')}
                                                getPath={(isRouteComponent: boolean = false) =>
                                                    DelegationsPathsV2.DELEGATION_ACCREDITATIONS(
                                                        getDelegationParams(isRouteComponent)
                                                    )
                                                }
                                            >
                                                <DelegationAccreditationsLoader />
                                            </TabViewResponsive.Section>
                                        )}

                                        {eventId &&
                                            data.organization.allowDelegationSpaceDownload && (
                                                <TabViewResponsive.Section
                                                    title={translate('documents_87028')}
                                                    getPath={(isRouteComponent: boolean = false) =>
                                                        DelegationsPathsV2.DELEGATION_DOCUMENTS(
                                                            getDelegationParams(isRouteComponent)
                                                        )
                                                    }
                                                >
                                                    <DelegationDocuments
                                                        delegationsSpaceCustomBadges={
                                                            data.organization
                                                                .delegationsSpaceCustomBadges
                                                        }
                                                    />
                                                </TabViewResponsive.Section>
                                            )}
                                    </TabViewResponsive.Root>
                                </Flex>
                            </>
                        }
                    />
                </Switch>
            </SpaceContainer>
        )
    );
};
